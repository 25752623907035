const Yup = require('yup');

const FILE_NAME_UNIQUE_ERROR_MESSAGE = 'File name is not unique. You can rename here.';

const BH_BUSINESS_FILE_NAME_UNIQUE_ERROR_MESSAGE =
  'File name is not unique and used by another business unit. You can rename here.';

/*
SUBMISSION_FILE_NAME_REG_EXP:
  - Allow: Numbers, alphabet characters, single space, single dot, special characters:  #, (, ), -
  - Disallow: Double dot, double space, leading or trailing dot/space, other special characters not mentioned above
*/
const SUBMISSION_FILE_NAME_REG_EXP = /^(?! )(?!.* $)(?!.* {2})(?!\.)(?!.*\.$)(?!.*?\.\.)(?!\w*["^*~<>|'/\\[\]`;&:?])[a-zA-Z0-9_ #()-.]+$/;
const SUBMISSION_FILE_NAME_NO_UNDERSCORES_REG_EXP = /^(?!.*_).+$/;

const SUBMISISON_FILE_MAX_LENGTH = 49;

const attachmentValidationSchema = ({ businessUnitAttachments, allAttachments, isEditMode }) =>
  Yup.object().shape({
    attachmentType: Yup.string().required().label('Attachment Type'),
    fileName: Yup.string()
      .uniqueInList({
        message: FILE_NAME_UNIQUE_ERROR_MESSAGE,
        list: businessUnitAttachments,
        mapper: ({ fileName }) => fileName,
        shouldSkipTest: isEditMode,
      })
      .uniqueInList({
        message: BH_BUSINESS_FILE_NAME_UNIQUE_ERROR_MESSAGE,
        list: allAttachments,
        mapper: ({ fileName }) => fileName,
        shouldSkipTest: isEditMode,
      })
      .label('File Name')
      .required(),
    submissionFileName: Yup.string()
      .label('Submission File Name')
      .matches(
        SUBMISSION_FILE_NAME_REG_EXP,
        'Submission file name has forbidden special characters',
      )
      .matches(
        SUBMISSION_FILE_NAME_NO_UNDERSCORES_REG_EXP,
        'Submission File Name field may not contain the _ character',
      )
      .max(
        SUBMISISON_FILE_MAX_LENGTH,
        `Submission file name must be less than ${SUBMISISON_FILE_MAX_LENGTH + 1} characters`,
      )
      .required(),
    attachmentDescription: Yup.string().label('Attachment Description').nullable(),
  });

const attachmentDbValidationSchema = ({ businessUnitAttachments, allAttachments, isUpdating }) =>
  Yup.object().shape({
    taxYear: Yup.string().required(),
    period: Yup.string().required(),
    jurisdictionId: Yup.string().required(),
    attachmentType: Yup.string().required(),
    fileName: Yup.string()
      .uniqueInList({
        message: FILE_NAME_UNIQUE_ERROR_MESSAGE,
        list: businessUnitAttachments,
        mapper: ({ fileName }) => fileName,
        shouldSkipTest: isUpdating,
      })
      .uniqueInList({
        message: BH_BUSINESS_FILE_NAME_UNIQUE_ERROR_MESSAGE,
        list: allAttachments,
        mapper: ({ fileName }) => fileName,
        shouldSkipTest: isUpdating,
      })
      .label('File Name')
      .required(),
    submissionFileName: Yup.string()
      .label('Submission File Name')
      .matches(
        SUBMISSION_FILE_NAME_REG_EXP,
        'Submission file name has forbidden special characters',
      )
      .matches(
        SUBMISSION_FILE_NAME_NO_UNDERSCORES_REG_EXP,
        'Submission File Name field may not contain the _ character',
      )
      .max(
        SUBMISISON_FILE_MAX_LENGTH,
        `Submission file name must be less than ${SUBMISISON_FILE_MAX_LENGTH + 1} characters`,
      )
      .required(),
    attachmentDescription: Yup.string().nullable(),
  });

module.exports = {
  attachmentValidationSchema,
  attachmentDbValidationSchema,
};
